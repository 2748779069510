module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1400,"linkImagesToOriginal":false,"backgroundColor":"#151515","quality":100},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-design-system/gatsby-browser.js'),
      options: {"plugins":[],"classPrefix":"grds"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
